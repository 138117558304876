<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { monthlyReportMethods } from "../../../state/helpers/monthlyreport";
import { authUserMethods } from "../../../state/helpers/authuser";
import { PDFDocument } from "pdf-lib";
import moment from 'moment';
import DatePicker from "vue2-datepicker";
import _ from "lodash";
/**
 * Blank page component
 */
export default {
  page: {
    title: "Monthly Report",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, DatePicker },
  data() {
    return {
      title: "Monthly Report",
      is_sort_asc: false,
      is_sort_ascdesc_enable: false,
      active_column: '',
      fields: [
        { key: "client_full_name", label: "Clients" },
        { key: "file_name", label: "Report" },
        { key: "month_year", label: "Month/Year" },
      ],
      reports: [],
      options: [],
      tab_items: [
        { id: 1, title: "Montly Reports" },
        { id: 2, title: "Generate Report" },
      ],
      is_tab_active: 1,
      client: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      page: 1,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      currentDate: new Date(),
      monthList: [],
      selected_month_year: moment().format("MMM-YYYY"),
      report_date: moment().format("MMM-YYYY"),
      pdfBytes: null,
      pdfSrc: null,
      search_options: [
        { id: 1, type: "Search report for Month/Year" },
        { id: 2, type: "Search report for Month/Year with client" },
        { id: 3, type: "Search report report by date range(start date and end date)" },
        { id: 4, type: "Search report report for date range with client" },
        { id: 5, type: "Search report for client Only" },
      ],
      active_search_option: 1,
      month: null,
      year: null,
      start_date: null,
      end_date: null,
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    this.generateMonthList();
    this.getAllMonthlyReport(this.selected_month_year);
  },
  methods: {
    ...authUserMethods,
    ...monthlyReportMethods,
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllMonthlyReport() {
      const data = this.selected_month_year
      return this.allMonthlyReports({date: data}).then(
        (response) => {
          // eslint-disable-next-line no-console
          this.reports = response.data.data;
          this.totalRows = response.data.total;
        }
      );
    },
    onPageChange(page) {
      const field = this.active_column
      this.is_sort_asc = !this.is_sort_asc
      const date =  this.selected_month_year
      let query;
      if(field != '') {
        query = { paginate: true,  ascdesc_sort: this.is_sort_asc ? 'asc' : 'desc', page, field, date}
      }else{
        query = { paginate: true, page, date }
      }

      return this.allMonthlyReports(query).then((response) => {
        // eslint-disable-next-line no-console
        this.page = page
        this.reports = response.data.data;
        this.totalRows = response.data.total;
      });
    },
    showFormContent(id) {
      this.is_tab_active = id;
    },
    async loadPDF(item) {
      const pdf = process.env.VUE_APP_STORAGE_PATH + item.path;

      // Load the PDF file
      const response = await fetch(pdf);
      const pdfData = await response.arrayBuffer();
      this.pdfBytes = new Uint8Array(pdfData);

      // Create a PDF document instance
      const pdfDoc = await PDFDocument.load(this.pdfBytes);

      // Get the first page of the PDF
      // eslint-disable-next-line no-unused-vars
      const firstPage = pdfDoc.getPage(0);

      // Serialize the modified PDF back to bytes
      const modifiedPdfBytes = await pdfDoc.save();

      // Convert the modified PDF bytes to a base64 data URL
      this.pdfSrc = `data:application/pdf;base64,${Buffer.from(
        modifiedPdfBytes
      ).toString("base64")}`;
      this.$bvModal.show("pdf-modal");
    },
    generateReport() {
      const date = moment(this.selected_month_year, "MMMM-YYYY").format(
        "YYYY-MM-DD"
      );
      let client_id;
      if (this.client !== null) {
        client_id = this.client.id
      }

      const data = {
        date,
        month_year: this.selected_month_year,
        client_id,
      };
      return this.createMonthlyReports(data).then(() => {
        // eslint-disable-next-line no-console
        let item;
        if (this.client !== null) {
          item = { client_id: this.client.id, date: this.selected_month_year }
        } else {
          item = { date: this.selected_month_year }
        }
        this.getAllMonthlyReport(item);
      });
    },
    filterPaymentWithMonthYear(event) {
      if (this.selected_month_year === null) {
        return this.getAllMonthlyReport();
      }
      const date = event.target.value;
      let item;
      if (this.client !== null) {
        item = { client_id: this.client.id, date }
      } else {
        item = { date }
      }

      this.getAllMonthlyReport(item);
    },
    filterReportWithStartDateEndDate() {
      // Clearing other filters
      // eslint-disable-next-line no-console

      // If both start_date and end_date are null, get all expenses
      if (this.start_date === null && this.end_date === null) {
        return this.getAllMonthlyReport();
      }

      // If start_date is null, return early
      if (this.start_date === null) {
        return;
      }

      // If end_date is null, return early
      if (this.end_date === null) {
        return;
      }

      let data;
      const active_search = this.active_search_option
      if (active_search == 3) {
        data = { start_date: this.start_date, end_date: this.end_date }
      }

      if (active_search == 4) {
        data = { start_date: this.start_date, end_date: this.end_date, client_id: this.client.id }
      }
      // Filter expenses based on the specified date range
      return this.allMonthlyReportByDateRange(data).then((response) => {
        this.reports = response.data.data;
        this.totalRows = response.data.total;
      })

    },
    generateMonthList() {
      const startMonth = this.currentDate.getMonth();
      const startYear = this.currentDate.getFullYear();

      this.monthList = [];

      let currentMonth = startMonth;
      let currentYear = startYear;

      for (let i = 0; i < 13; i++) {
        const month = new Date(currentYear, currentMonth);
        this.monthList.push(moment(month).format("MMM-YYYY"));

        if (currentMonth === 11) {
          currentMonth = 0;
          currentYear++;
        } else {
          currentMonth++;
        }
      }

      this.getAllMonthlyReport();
    },

    goToPreviousMonth() {
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1,
        1
      );
      // eslint-disable-next-line no-console
      this.selected_month_year = moment(this.currentDate).format("MMM-YYYY")
      this.generateMonthList();
    },
    goToNextMonth() {
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        1
      );
      this.selected_month_year = moment(this.currentDate).format("MMM-YYYY")
      this.generateMonthList();
    },
    formatMonth(month) {
      const options = { month: "long", year: "numeric" };
      const formattedDate = month.toLocaleDateString(undefined, options);
      const [formattedMonth, formattedYear] = formattedDate.split(" ");
      return `${formattedMonth} ${formattedYear}`;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    // eslint-disable-next-line no-unused-vars
    search: _.debounce((loading, search, vm) => {
      vm.allClient({ paginate: true, keyword: search }).then(
        (response) => {
          // eslint-disable-next-line no-console
          vm.options = response.data.data;
          loading(false);
        }
      );
    }, 100),

    searchReportForClient(data) {
      // eslint-disable-next-line no-console
      const active_search = this.active_search_option;
      if (data === null) return this.getAllMonthlyReport(this.selected_month_year);
      let item;
      if (active_search == 2) {
        item = { client_id: this.client.id, date: this.selected_month_year }
      }
      if (active_search == 5) {
        item = { client_id: this.client.id }
      }

      if (active_search == 4) {
        return this.filterReportWithStartDateEndDate()
      }
      return this.getAllMonthlyReport(item);
    },
    handleSearchOption(event) {
      // eslint-disable-next-line no-console
      this.active_search_option = event.target.value;
      this.client = null;
      this.start_date = null;
      this.end_date = null;
      this.selected_month_year = moment().format("MMM-YYYY"),
        this.getAllMonthlyReport();
    },
    async handleTableHeadClicked(item){
      const date = this.selected_month_year
      this.active_column = item
      this.is_sort_asc = !this.is_sort_asc

      let query = { paginate: true,  ascdesc_sort: this.is_sort_asc ? 'asc' : 'desc', date, page: this.page, field: item}

      return this.allMonthlyReports(query).then(
        (response) => {
          // eslint-disable-next-line no-console
          this.reports = response.data.data;
          this.totalRows = response.data.total;
        }
      );
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-10">
        <b-card no-body class="mt-5">
          <div class="bp_form_box">
            <div class="bp_form_side_bar">
              <div v-for="(data, index) in tab_items" :key="index" class="bp_tab_link"
                :class="is_tab_active === data.id ? 'bp_active' : ''" @click="showFormContent(data.id)">
                <div class="bp_tab_counter">
                  {{ index + 1 }}
                </div>
                <div class="bp_text_view">
                  <span> {{ data.title }} </span>
                </div>
              </div>
            </div>

            <div class="bp_form_view">

              <b-card-body v-if="is_tab_active === 1">

                <!-- <b-card-title
              class="d-flex mb-3 align-items-center justify-content-between"
            >
            <div class="search_option_input_view">
              <label class="form-label mt-2" for="item1">Search Option for Monthly Report</label>
            <select v-model="active_search_option" class="form-control" @change="handleSearchOption">
              <option v-for="{id, type}  of search_options" :value="id" :key="id"> {{ type }} </option>
            </select>
            </div>

          </b-card-title> -->
                <b-card-title class="d-flex mb-5 align-items-center justify-content-between">
                  <h1 class="card-title">Monthtly Report List</h1>

                </b-card-title>


                <div class="search_input_option_view">
                  <div class="date_range_view" v-if="active_search_option == 3 || active_search_option == 4">
                    <div class="split">
                      <label class="form-label mt-2" for="item1">Start Date</label>
                      <date-picker id="item1" v-model="start_date" format="DD MMMM, YYYY" class="" lang="en"
                        @change="filterReportWithStartDateEndDate"></date-picker>
                    </div>
                    <div class="split">
                      <label class="form-label mt-2" for="item1">End Date</label>
                      <date-picker id="item1" v-model="end_date" format="DD MMMM, YYYY" class="" lang="en"
                        @change="filterReportWithStartDateEndDate"></date-picker>
                    </div>
                  </div>

                  <div class="month_year_view" v-if="active_search_option == 1 || active_search_option == 2">
                    <label class="form-label mt-2" for="item1">Month/Year</label>
                    <div class="d-flex align-items-center">
                      <button type="button" class="btn btn-outline-success" @click="goToPreviousMonth">
                        Prev Month
                      </button>
                      <select id="item1" v-model="selected_month_year" class="form-select form-control join_select_input"
                        @change="filterPaymentWithMonthYear" style="width:100px;">
                        <option v-for="(month, index) in monthList" :key="index" :value="month">{{ month }}</option>
                      </select>
                      <button type="button" class="btn btn-outline-success" @click="goToNextMonth">
                        Next Month
                      </button>
                    </div>
                  </div>

                  <div class="property_search_view"
                    v-if="active_search_option == 2 || active_search_option == 4 || active_search_option == 5">
                    <label class="form-label mt-2" for="item1">Property Owner(Client)</label>
                    <v-select v-model="client" label="full_name" :filterable="false" :options="options"
                      @search="onSearch" class="" :placeholder="'Select property owner'"
                      @input="searchReportForClient">
                      <template slot="no-options">
                        type to search property owner
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.full_name }}
                        </div>
                      </template>
                    </v-select>
                  </div>

                </div>


                <b-card-text>
                  <!-- Table -->
                  <div class="table-responsive mb-0 datatables report_table">
                    <b-table :items="reports" :fields="fields" responsive="sm" id="app-table" striped="striped"
                      borderless="borderless" outlined="outlined" fixed="fixed" sticky-header="true"
                      @row-clicked="loadPDF"
                      @head-clicked="handleTableHeadClicked"
                      >

                      <template #head()="data">
                        <div class="table_heading_view">
                          <span class="">{{ data.label }}</span>
                          <i class="fas fa-sort-amount-down-alt  text-success" id="sort-2-tooltip"
                            v-if="is_sort_asc && data.column === active_column"></i>
                          <i class="fas fa-sort-amount-up  text-info" id="sort-2-tooltip"
                            v-if="!is_sort_asc && data.column === active_column"></i>
                        </div>

                      </template>
                      <!-- <template #cell(property_owner_id)="data">
                        {{ data.item.owner_first_name }}
                        {{ data.item.owner_last_name }}
                      </template> -->
                    </b-table>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="dataTables_paginate paging_simple_numbers float-end">
                        <ul class="pagination pagination-rounded mb-0">
                          <!-- pagination -->
                          <b-pagination v-model="currentPage" @change="onPageChange" :total-rows="totalRows"
                            :per-page="perPage"></b-pagination>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <b-modal id="pdf-modal" title="Monthly Report" title-class="font-18" size="xl" hide-footer>
                    <iframe :src="pdfSrc" frameborder="0" width="100%" style="height: 80vh;"></iframe>
                  </b-modal>
                </b-card-text>
              </b-card-body>

              <b-card-body v-if="is_tab_active === 2">
                <b-card-title class="d-flex mb-4 align-items-center">
                  <div class="row" style="flex:0 1 60%;">
                    <div class="col-md-12">
                      <label class="form-label mt-2" for="item1">Reports For Month/Year</label>
                    </div>
                    <div class="col-md-8 d-flex align-items-center">
                      <button type="button" class="btn btn-outline-primary" @click="goToPreviousMonth">
                        Prev Month
                      </button>
                      <select id="item1" v-model="selected_month_year" class="form-select form-control join_select_input"
                        @change="filterPaymentWithMonthYear">
                        <option v-for="(month, index) in monthList" :key="index" :value="month">{{ month }}</option>
                      </select>
                      <button type="button" class="btn btn-outline-primary" @click="goToNextMonth">
                        Next Month
                      </button>
                    </div>
                  </div>

                  <div style="flex:0 1 50%;">
                    <label class="form-label mt-2" for="item1">Reports For Client</label>
                    <v-select v-model="client" label="full_name" :filterable="false" :options="options"
                      @search="onSearch" class="" :placeholder="'Select Client'" @input="searchReportForClient">
                      <template slot="no-options">
                        type to search property owner
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.full_name }}
                        </div>
                      </template>
                    </v-select>
                  </div>
                </b-card-title>
                <b-card-title class="d-flex mb-5 align-items-center justify-content-between">
                  <a href="javascript:void(0);" class="btn btn-outline-success btn-block" @click="generateReport">Generate
                    Monthly Reports For {{ selected_month_year }}</a>
                </b-card-title>
              </b-card-body>
            </div>
          </div>
        </b-card>
      </div>
    </div>
  </Layout>
</template>
